import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.js';



const getRoutes = (data)=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter='';
    if(data.filter&& Object.keys(data.filter).length ){
    for (const [key, value] of Object.entries(data.filter)) {
            filter+=`&filters[${key}]=${value}`
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
    return axios.get(constants.URL + `api/v1/active-routes?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });
}

const getRouteItem = (data)=>{
    return axios.get(constants.URL +'api/v1/active-routes/'+data.id,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const getRoutePoints = (data)=>{
    return axios.get(constants.URL +'api/v1/active-routes/'+data.id+'/no-protection',{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}



const editRoute = (data)=>{
    return axios.put(constants.URL + 'api/v1/active-routes/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const favoutiteRouteItem = (data)=>{
    

}

const createRoute = (data)=>{
    return axios.post(constants.URL + 'api/v1/active-routes',{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const deleteRoute = (data)=>{
    return axios.delete(constants.URL + 'api/v1/active-routes/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


export default {
    getRoutes,
    createRoute,
    getRouteItem,
    editRoute,
    deleteRoute,
    getRoutePoints,
    favoutiteRouteItem
}