import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';

const getParksCoordinators  = (data)=>{
    return axios.get(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}
const getParksCoordinatorsAll  = (data)=>{
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter+=`&filters[${key}]=${el}`
            });
        }
    }    
    if(data.sort&& Object.keys(data.sort).length ){
        
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }  
    return axios.get(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators/all?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}



const addParkCoordinatorsEmail = (data)=>{
    
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators`,{...data},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    
}
const addParkCoordinatorsId = (data)=>{
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators/${data.userId}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
            console.log(e);
        });
       
}
const addParkCoordinatorsApprove = (data)=>{
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators/${data.userId}/approve`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
            console.log(e);
        });
      
}
const addParkCoordinatorsDeactivate = (data)=>{
        return axios.post(constants.URL + `api/v1/sportsgrounds/${data.id}/coordinators/${data.userId}/deactivate`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
            console.log(e);
        });
     
}
const deleteParkCoordinators = (data)=>{
    return axios.delete(constants.URL + 'api/v1/sportsgrounds/' + data.id +'/coordinators/'+data.userId,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

export default{
    getParksCoordinators,
    addParkCoordinatorsEmail,
    addParkCoordinatorsId,
    addParkCoordinatorsApprove,
    addParkCoordinatorsDeactivate,
    deleteParkCoordinators,
    getParksCoordinatorsAll
}