import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';


const getCounter = () => {
   
    return axios.get(constants.URL + `api/v1/users/count-user`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}


export default {
    getCounter
}