import React, { useEffect, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addVideo, getVideos } from "../../redux/actions/video";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Paggination/Paggination";
import Filter from "./Filter/Filter";
import Select from "../../components/Select/";

const sortOtions = [
  { id: "updatedAt", value: "За датою редагування" },
  { id: "title", value: "За абеткою" },
  { id: "duration", value: "За таймінгом" },
  { id: "likes", value: "За рейтингом" },
  { id: "views", value: "За переглядами" },
  { id: "statusId", value: "За статусом" },
];

const VideoCatalogPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [intervalId,setIntervalId] = useState(null);  
  const [ready, setReady] = useState(false);
  const [modal, setModal] = useState(false);
  const [sortData, setSortData] = useState("updatedAt");
  const [sortName, setSortName] = useState("За датою редагування");
  const [sortValue, setSortValue] = useState("desc");
  const { listId } = useSelector((state) => state);
  const { videos } = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(false);
  const [filterArr, setFilterArr] = useState({});
  const [isTro, setIsTro] = useState(false);


  const searchChange = (e) => {
    let filter = {};
    setSearchValue(e.target.value);
    if (localStorage.getItem("videoFilter")) {
      filter = JSON.parse(localStorage.getItem("videoFilter"));
    }
    filter.all = [];
      clearTimeout(intervalId);
      const newIntervalId = setTimeout(() =>{
      // Call your search function with the updated query value
    
      if (e.target.value.length > 2) {
        filter.all[0] = e.target.value;
        localStorage.setItem("videoFilter", JSON.stringify(filter));
        getVideoList(1, filter);
      } else {
        localStorage.setItem("videoFilter", JSON.stringify(filter));
        getVideoList(1, filter);
      }
    }, 1000)
    setIntervalId(newIntervalId);  ;

  };

  const selectClick = () => {
    modal ? setModal(false) : setModal(true);
  };

  const AddVideo = (e) => {
    setReady(false);
    e.preventDefault();
    dispatch(addVideo())
      .then((res) => {
        navigate(`/videoItem/${res.id}/info`);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  
  const sortClick = (e) => {
    setSortName(e.target.innerHTML);
    const currentIdIndex = sortOtions.findIndex(
      (x) => x.value === e.target.innerHTML
    );
    setSortData(sortOtions[currentIdIndex].id);
    let data = {};
    if (sortData === sortOtions[currentIdIndex].id && sortValue === "asc") {
      setSortValue("desc");
      data[sortOtions[currentIdIndex].id] = "desc";
    } else {
      data[sortOtions[currentIdIndex].id] = "asc";
      setSortValue("asc");
    }
    localStorage.setItem("videoSort", JSON.stringify(data));
    getVideoList();
    setModal(false);
  };

  const getVideoList =(page = 1, filter) => {
    let data = {};
    if (filter) {
      data.filter = { ...filter };
    } else {
      if (localStorage.getItem("videoFilter")) {
        data.filter = { ...JSON.parse(localStorage.getItem("videoFilter")) };
      } else {
        data.filter = {};
      }
    }
    if (localStorage.getItem("videoSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("videoSort")) };
    } else {
      data.sort = { updatedAt: "asc" };
    }


    data.offset = (page - 1) * 10;
    data.limit = 10;
    dispatch(getVideos(data)).then((res) => {
      setReady(true);
    });
  };
  const resetFilter = () => {
    localStorage.removeItem("videoFilter");
    setFilterArr({});

    getVideoList(1,{'isTro':[isTro?'1':0]});
  };

  useEffect(() => {
    if (!ready) {
      if (localStorage.getItem("videoFilter")) {
        let filter = JSON.parse(localStorage.getItem("videoFilter"));
        getVideoList(1, filter);
        setFilterArr(filter);
      } else {
        getVideoList();
      }
    }
  }, [ready]);

  if (!ready || !videos.items || !listId.list) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  } else if (ready && videos.items && listId.list) {
    return (
      <>
        {filter && (
          <Filter
            setModal={setFilter}
            getVideoList={getVideoList}
            setFilter={setFilterArr}
          ></Filter>
        )}
        <div className="headerWithButtonsCommonBlock">
          <div className="headerWithButtonsHeaderBlock">
            <h1>Каталог відео</h1>
          </div>
          <div className="headerWithButtonsButtonBlock">
            {searchOpen && (
              <div style={{ marginBottom: "4px" }} className="apInputText">
                <label htmlFor="search">Пошук</label>
                <input
                  id="search"
                  type="text"
                  placeholder="Мінімум 3 символи"
                  value={searchValue}
                  onChange={searchChange}
                />
              </div>
            )}
            <button
              className="secondaryButtonIconDefault"
              onClick={() => setSearchOpen(!searchOpen)}
            >
              <svg>
                <use href="/images/icon24Search.svg#icon24" />
              </svg>
            </button>
          </div>
        </div>
        <div className="sortAndFilterBlock">
          <div className="menuFunctionLine">
            <div className="actionButtonsBlock">
              <button
                className="primaryButtonIconTextDefault"
                onClick={AddVideo}
              >
                <svg>
                  <use href="/images/icon24Plus.svg#icon24" />
                </svg>
                Додати відео
              </button>
              <div className="filterBlock">
                <button
                  className="secondaryButtonIconTextDefault"
                  onClick={() => setFilter(true)}
                >
                  <svg>
                    <use href="/images/icon24Filter.svg#icon24" />
                  </svg>
                  Фільтр
                </button>
                <span>Знайдено {videos.total} відео:</span>
                {filterArr &&
                  Object.keys(filterArr).length > 0 &&
                  Object.keys(filterArr).map((el) => {
                    return (
                      filterArr[el].length > 0 &&
                      filterArr[el].map((elem, index) => {
                        return (
                          <div key={index} className="apChips">
                            {listId.list[elem]}
                            {/* <svg><use href="/images/icon24CancelSmall.svg#icon24" /></svg> */}
                          </div>
                        );
                      })
                    );
                  })}
                {filterArr && Object.keys(filterArr).length > 0 && (
                  <button
                    onClick={resetFilter}
                    className="secondarySmallRoundedButtonTextDefault"
                  >
                    Скинути
                  </button>
                )}
              </div>
              <div className="actionTriggersBlock">
            <div className="apCheckBoxWithLabel">
              <label htmlFor="closedGroundSwiper">Офіційні відео</label>
              <input id="closedGroundSwiper" checked={isTro} type="checkbox" className="apCheckbox" onChange={(e)=>{getVideoList(1,{'isTro':[isTro?'0':'1']});setIsTro(!isTro)}}/>
              <label htmlFor="closedGroundSwiper">Адаптивні</label>
            </div>
          </div>
            </div>
            <div className="sortBlock">
            <Select
              label={"Сортування:"}
              value={sortName}
              options={sortOtions}
              onSet={sortClick}
            />
          </div>
          </div>
         
        </div>

        <div className="pageContentHeaderDivider" />
        <div className="dictAllRowsContainer">
          {videos &&
            videos.items &&
            videos.items.length > 0 &&
            videos.items.map((el, index) => {
              return (
                <div key={index} className="catVideosRowNormal">
                  <div className="catVideoValue">
                    <div
                      style={{ backgroundImage: `url("${el.mainPhoto}")` }}
                      className="myObjectValueImage"
                    />
                  </div>
                  <div
                    className="catVideoValue"
                    onClick={() => navigate(`/videoItem/${el.id}/info`)}
                  >
                    {el.title}
                  </div>
                  <div className="catVideoValue">
                    {listId.list[el.categoryId]}
                    <span>{listId.list[el.exerciseDifficultyLevelId]}</span>
                  </div>
                  <div className="catVideoValue">
                    {Math.floor(el.duration / 60 / 60) > 0 &&
                      Math.floor(el.duration / 60 / 60)}{" "}
                    {Math.floor(el.duration / 60) -
                      Math.floor(el.duration / 60 / 60) * 60}
                    :{(el.duration % 60).toFixed(0)} хв.
                    <span>Таймінг</span>
                  </div>
                  <div className="catVideoValue">
                    {el.statusId === "66e2ec16-03a1-4367-834a-d6b87ee709bd" && (
                      <div className="apPublished">Опубліковано</div>
                    )}
                    {el.statusId === "f7b77851-1d35-4ab1-9fcd-ef6cd2b88ace" && (
                      <div className="apDeactivated">Деактивовано</div>
                    )}
                    {el.statusId === "def3497e-3498-4a17-a514-e99ec83676b4" && (
                      <div className="apCandidate">Кандидат</div>
                    )}
                    {el.statusId === "73f891c0-a79f-4afc-bd79-d529a5eb5774" && (
                      <div className="apOnRepair">Чернетка</div>
                    )}
                  </div>
                  <div className="catVideoValue">
                    {el.updatedAt}
                    <span>Змінено</span>
                  </div>
                  <div className="catVideoValue">
                    <svg>
                      <use href="/images/icon24Star.svg#icon24" />
                    </svg>
                    {el.likes}
                  </div>
                </div>
              );
            })}
        </div>
        {videos.total > 10 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={videos.total.toFixed(0)}
            pageSize="10"
            onPageChange={async (page) => {
              await setCurrentPage(page);
              await getVideoList(page);
            }}
          />
        )}
      </>
    );
  }
};

export default VideoCatalogPage;
