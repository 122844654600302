import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';


const getFcParticipant = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/clubs/participant?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}
const getFcModerator = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/clubs/moderation?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}

const getFcCreator = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10;
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/clubs/my?userId=${data.id}&offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}
const getFcDeacticvate = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10;
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/clubs/deactivate?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {
        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}


const getFcList = (data) => {

    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = '';

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/clubs?offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}


const addFc = (data) => {

    if (data) {
        return axios.post(constants.URL + `api/v1/clubs/${data.id}/${data.type}`, {}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    } else {
        return axios.post(constants.URL + `api/v1/clubs`, {}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    }

}



const editFc = (data) => {
    return axios.put(constants.URL + 'api/v1/clubs/' + data.id, {
            ...data
        }, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
            return Promise.reject(e);
        });
}

const getFcItem = (data) => {
    return axios.get(constants.URL + 'api/v1/clubs/' + data.id, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const deleteFc = (data) => {
    return axios.delete(constants.URL + 'api/v1/clubs/' + data.id, {
            headers: authHeader()
        })
        .then((response) => {

            return response.data
        })
        .catch((e) => {
            console.log(e);
        });
}
const createQr =(data)=>{
    return axios.post(constants.URL + `api/v1/qr-code-club/to-join-club`,{...data}, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((e) => {

        console.log(e);
    });
}
const readeQr =(data)=>{
    return axios.get(constants.URL + `api/v1/qr-code-club/${data.id}`, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((e) => {

        console.log(e);
    });
}



export default {
    getFcCreator,
    getFcParticipant,
    addFc,
    editFc,
    getFcItem,
    deleteFc,
    getFcList,
    getFcModerator,
    createQr,
    readeQr,
    getFcDeacticvate
}