import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';



const getReports = (data)=>{

    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/reports?offset=${data.offset}&limit=${data.limit}&sort[startsAt]=asc${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}
const getReportsUser = (data)=>{

    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=100
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/reports-coordinator?offset=${data.offset}&limit=${data.limit}&sort[startsAt]=asc${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}



const getReportsList = (data)=>{

    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/sport-events/reports-user?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const getReportsUserList = (data)=>{

    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
    if(data.filter&& Object.keys(data.filter).length ){
        for (const [key, value] of Object.entries(data.filter)) {
            value.forEach(el => {
                filter+=`&filters[${key}]=${el}`
            });
        }
    }
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }
    return axios.get(constants.URL + `api/v1/user-activity-statistics?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}




const editReport = (data)=>{
    return axios.put(constants.URL + `api/v1/sport-events/${data.id}/reports/`,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


const pdfReport = (data)=>{
    return axios.post(constants.URL + `api/v1/pdf/createpdf.php`,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}



export default{
    getReports,
    editReport,
    pdfReport,
    getReportsList,
    getReportsUser,
    getReportsUserList
}