import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper";
import { Status } from "../../../components/Status";
import getDate from "../../../helpers/getDate";
import {getNearest } from "../../../redux/actions/events";


const EventsBlock=()=>{
    const [ready, setReady] = useState(false);
    const { events } = useSelector((state) => state);
    const navigate =useNavigate();
    const { list } = useSelector((state) => state.listId);
    const dispatch = useDispatch();


    const userClick =(e)=>{
        e.preventDefault();
        e.stopPropagation();
        const id = e.currentTarget.id;
        navigate('/personalPage/' + id);
    }
    const eventClick =(e)=>{
        navigate('/fc-events/0/'+e.target.id)
    }
    const initFunc=(page = 1, filter)=>{
        let data = {};
    if (localStorage.getItem("eventsSort")) {
      data.sort = { ...JSON.parse(localStorage.getItem("eventsSort")) };
    } else {
      data.sort = { startsAt: "asc" };
    }
    dispatch(getNearest(data)).then((res) => {
      setReady(true);
    });
      }
      useEffect(() => {
         if(!ready){
             initFunc()
         }
      }, [ready]);


return(
          <section className="main-section main-events">
        {ready&& events?.items?.length !== 0 && events?.items?.length<10 && (
          <>
            <div className="headline" style={{marginTop:'40px'}}>
              <h2>Найближчі заходи:</h2>
              <button className="mobileHidden" onClick={() => navigate("/eventsCalendar")}>
                Всі заходи
              </button>
            </div>
            <Slider
              modules={[Navigation, Pagination, EffectFade]}
              navigation
              pagination={{ clickable: true }}
              watchOverflow
              loop={false}
              spaceBetween={1}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
            >
              {events.items.map((el, index) => {
                if (el.holdingStatusId==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp'){
                  // setEventsShown(eventsShown-1);
                  return(<></>)
                }
                return (
                
                <Slide key={index}>
                  <div className="startPageItem"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                      (el.imageUrl !== null &&
                        el.imageUrl.length !== 0 &&
                        el.imageUrl) ||
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }')`,
                  }}
                  id={el.id}
                  onClick={eventClick}
                  >
                      <div className="leftsideInfoStartPage">
                        {/* <div  id={el.id}> */}
                        <div   id={el.id} className="leftsideInfoStartPageHeader">{el.title}</div>
                        <div  id={el.id} className="leftsideInfoStartPageSubText"> <Status type={"eventHolding"} statusId={el.holdingStatusId} isRound={true} />{getDate(el.startsAt,'dd/mm/yyyy hh:mm')}-{getDate(el.finishesAt,'hh:mm',':')} {el.typeId!='848e3121-4a2b-413d-8a8f-ebdd4ecf2840' && list[el.typeId]}</div>
                      </div>
                     {el.createdBy?.photo?
                      <div className={el.maxCountUser>0 ? "roundUser amountUserAdd": "roundUser"} style={{
                          backgroundImage: `url('${el.createdBy.photo}')`,
                      }} id={el.createdBy.id} onClick={userClick}>
                      </div>
                      :
                      el?.createdBy? <div  className={el.maxCountUser>0 ? "roundUser amountUserAdd": "roundUser"}  id={el?.createdBy?.id} onClick={userClick}>
                        {
                        el?.createdBy?.lastName?.length > 0
                            ? el?.createdBy?.lastName[0] + el?.createdBy?.firstName[0]
                            : (el?.createdBy?.nickname?.length > 1 ? el?.createdBy?.nickname[0].toUpperCase() + el?.createdBy?.nickname[1].toUpperCase() : '')
                        }</div>:''}
                      {el.countUser>1 && <div className={el.maxCountUser>0 ? "roundUser amountUserAdd roundUserAdded": "roundUser roundUserAdded"} id={el.id}>+{el.countUser-1}</div>}
                      {el.maxCountUser>0 && <div className='amountUser'>
                        Вільних місць {el.maxCountUser-el.countUser}
                    </div>}
                  </div>
                </Slide>
              )})}
            </Slider>
            <button className="mobileShow" onClick={() => navigate("/eventsCalendar")}>
                Всі заходи
            </button>

          </>
        )}
      </section>
)
}

export default EventsBlock;