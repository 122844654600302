import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.js';

const register = (form) => {

  return axios.post(constants.URL + "api/v1/auth/signup", 
    {...form}
  ).then((response) => {
       
    return response;
  }).catch((e)=>{
          return e.response
  });
};

const registerNew = (form) => {
  return axios.post(constants.URL + "api/v1/auth-new/verify-code-phone", 
    {...form}
  ).then((response) => {
    if (response.data.token && response.data.refreshToken) {
      localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return response;
  }).catch((e)=>{
          return e.response
  });
};

const restore = (data)=>{
    
  return axios.post(constants.URL + "api/v1/auth-new/change-password-reset", {
    ...data
  }).then(response=>{
    return response;
  }).catch((e)=>{
    return e.response
});

}
const changePassword = (data)=>{
    
  return axios.post(constants.URL + `api/v1/users/${data.id}/change-password`, {
    ...data
  },{headers: authHeader()}).then(res=>{
      return res
  })

}

const getCode =(data)=>{
  let email = data.email;
     
  return axios.post(constants.URL + "api/v1/users/email", {
    email
  }).then(res=>{
  })
}
const getCodeNew =(data)=>{     
  return axios.post(constants.URL + "api/v1/auth-new/change-password",data).then(res=>{
  })
}
const getPhoneCode =(data)=>{
  return axios.post(constants.URL + "api/v1/auth-new/send-code-phone", data).then(res=>{
    return res
  }).catch((e)=>{
    return e.response
  });
}


const refresh=(refreshToken)=>{
  let data = {refreshToken:refreshToken}
  return axios.post(constants.URL + "api/v1/auth/refresh",data,{headers: authHeader()})
  .then((response) => {
    if (response.data.token && response.data.refreshToken) {
      localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return response.data;
  }).catch((e)=>{
    
    console.log(e);
    logout();
  });;
}

const login = (email,password) => {
  return axios
    .post(constants.URL + "api/v1/auth/login", {
      email, password,typeId:0
    })
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      
      return e.response.data;
    });
};
const loginNew = (data) => {
  return axios
    .post(constants.URL + "api/v1/auth-new/login-new", data)
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      return e.response.data;
    });
};


const editInfo =(data)=>{
  return axios
    .put(constants.URL + "api/v1/auth-new/"+data.id, data,{headers: authHeader()})
    .then((response) => {
       
      return response.data;
    }).catch((e)=>{
       
      return e.response.data;
    });
}
const loginFacebook =(data)=>{
  return axios
    // .post(constants.URL + "api/v1/auth-new/login-using-facebook", data)
    .post(constants.URL + "api/v1/auth-new/simple-login-using-facebook", data)
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      return e.response.data;
    });
}

const loginGoogle =(data)=>{
  return axios
    // .post(constants.URL + "api/v1/auth-new/login-using-google", data)
    .post(constants.URL + "api/v1/auth-new/simple-login-using-google", data)
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      return e.response.data;
    });
}

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  // localStorage.removeItem("userData");
};
const codedia = (data)=>{
  return axios
    .post(constants.URL + "api/v1/auth-new/code",data)
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      return e.response.data;
    });
}

export default {
  register,
  login,
  logout,
  refresh,
  restore,
  changePassword,
  getCode,
  getCodeNew,
  getPhoneCode,
  registerNew,
  loginFacebook,
  editInfo,
  loginNew,
  loginGoogle,
  codedia
};






