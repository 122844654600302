import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';

const getWorkouts = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/workouts-activity?sort[createdAt]=desc`, {

    headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}


const getWorkoutsPlan = (data) => {
    if (!data.offset) {
        data.offset = 0;
    }
    if (!data.limit) {
        data.limit = 10
    }
    let filter = ''

    if (data.filter && Object.keys(data.filter).length) {

        for (const [key, value] of Object.entries(data.filter)) {

            value.forEach(el => {

                filter += `&filters[${key}]=${el}`
            });
        }
    }
    if (data.sort && Object.keys(data.sort).length) {
        for (const [key, value] of Object.entries(data.sort)) {
            filter += `&sort[${key}]=${value}`
        }
    }

    return axios.get(constants.URL + `api/v1/workouts/plan?week=${data.week}&userId=${data.id}&offset=${data.offset}&limit=${data.limit}${filter}`, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {

        console.log(e);
    });;
}





const addWorkouts= (data) => {

    if (data) {
        return axios.post(constants.URL + `api/v1/workouts`, {...data}, {
                headers: authHeader()
            })
            .then((response) => {
                
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    } else {
        return axios.post(constants.URL + `api/v1/workouts`, {...data}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });
    }

}


const addWorkoutsVideo= (data) => {

        return axios.post(constants.URL + `api/v1/videos/${data.videoId}/create-workout`, {...data}, {
                headers: authHeader()
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {

                console.log(e);
            });

}

const changeAccess= (data) => {
    return axios.post(constants.URL + `api/v1/workouts/${data.type}`, {...data}, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
        });

}


const editWorkouts = (data) => {
    return axios.put(constants.URL + 'api/v1/workouts-activity/' + data.id, {
            ...data
        }, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {

            console.log(e);
        });
}

const getWorkoutsItem = (data) => {
    return axios.get(constants.URL + 'api/v1/workouts-activity/' + data.id, {
        headers: authHeader()
    }).then(response => {

        return response.data
    }).catch((e) => {
        console.log(e);
    });;
}

const deleteWorkouts = (data) => {
    return axios.delete(constants.URL + 'api/v1/workouts-activity/' + data.id, {
            headers: authHeader()
        })
        .then((response) => {

            return response.data
        })
        .catch((e) => {
            console.log(e);
        });
}


const exitWorkout= (data) => {
    return axios.post(constants.URL + `api/v1/workouts/activity/${data.id}/exit`, {...data}, {
            headers: authHeader()
        })
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            console.log(e);
        });

}

const addWorkoutNote =(data)=>{
    return axios.post(constants.URL + `api/v1/workouts/${data.id}/notes`, {...data}, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((e) => {
        console.log(e);
    });
}

const editWorkoutNote =(data)=>{
    return axios.put(constants.URL + `api/v1/workouts/${data.workoutId}/notes/${data.id}`, {
        ...data
    }, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((e) => {

        console.log(e);
    });
}

const deleteWorkoutNote =(data)=>{
    return axios.delete(constants.URL + `api/v1/workouts/${data.workoutId}/notes/${data.id}`, {
        headers: authHeader()
    })
    .then((response) => {

        return response.data
    })
    .catch((e) => {
        console.log(e);
    });
}

const setVoteTrainig =(data)=>{
    return axios.put(constants.URL + `/api/v1/workouts/${data.id}/exercise-evaluation`,{...data}, {
        headers: authHeader()
    })
    .then((response) => {
        return response.data
    })
    .catch((e) => {
        console.log(e);
    });
}






export default {
    getWorkouts,
    getWorkoutsPlan,
    addWorkouts,
    addWorkoutsVideo,
    editWorkouts,
    getWorkoutsItem,
    deleteWorkouts,
    changeAccess,
    exitWorkout,
    addWorkoutNote,
    editWorkoutNote,
    deleteWorkoutNote,
    setVoteTrainig
}