import React, { useMemo, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Icon } from "../../components/Icon";
import Loader from "../../components/Loader/Loader";
import { Tabbar } from "../../components/Tabbar";
import { getEventsCurrentItem } from "../../redux/actions/events";
import About from "./components/About";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { EventHeader } from "./components/Header";
import { Table } from "./components/Map/Table";
import { Itinerary } from "./components/Itinerary";
import { Users } from "./components/Users";
import { changeFcEventsUserStatus } from "../../redux/actions/fcEventsUsers";
import getDate from "../../helpers/getDate";
import QrComponent from "./components/Qr";
import Gallery from "./components/Gallery/Gallery";
import constants from '../../constants/constant.js';
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import ModalAcceptComponent from "./components/ModalAcceptComponent";
import { updateUser } from "../../redux/actions/users";

export const FcEvents = () => {
  const [ready, setReady] = useState(false);
  let contentId = useParams().contentId;
  let currentId = useParams().id;
  const clubid = useParams().clubid;
  const { currentUser } = useSelector((state) => state.currentUser);
  const { currentEvent } = useSelector((state) => state);
  const [modalQrOpen, setModalQrOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [form, setForm] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [modalAccept, setModalAccept] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  
  
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdmin = useMemo(() => {
    if (!currentUser || currentUser === null) return;
    switch (currentUser.roleId) {
      case "631db81f-fa07-42e2-b394-062c9be66b09":
        return true;
      default:
        return false;
    }
  }, [currentUser]);
  const joinEvent = async () => {
    try{if(currentEvent.isPrivate){
      dispatch(updateUser(form)).then(res=>{
        let data = { id: currentId, type: "apply" };
        dispatch(changeFcEventsUserStatus(data)).then((res) => {
          setReady(false);
        });
      })
    }else{
      let data = { id: currentId, type: "join" };
      if(currentEvent.isPersonalInfo){
        if(form.id){
          await dispatch(updateUser(form)).then(async (res)=>{
             
            await dispatch(changeFcEventsUserStatus(data)).then((res) => {
               
              setReady(false);
            });
          }).catch(e=>{
             

          })
        }else{
          await dispatch(changeFcEventsUserStatus(data)).then((res) => {
          
            setReady(false);
          }).catch(e=>{
             
          })
        }
       
      }else{
        await dispatch(changeFcEventsUserStatus(data)).then((res) => {
           
          setReady(false);
        });
      }
    }
    }catch (e){
       
    }
    
  };

  const tryJoinEvent =()=>{
    setModalAccept(true);
  }

  const copyClick=()=>{
    let copyText = constants.URL +'fc-events/0/' +currentId;
    navigator.clipboard.writeText(copyText);
    setAlert(true);
    setAlertMessage('Скопійовано');
  }


  const applyEvent = () => {
    let data = { id: currentId, type: "apply" };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      setReady(false);
    });
  };
  const leaveClub = () => {
    let data = { id: currentId, type: "leave", userId:currentUser.id };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      setReady(false);
    });
  };

  const cancelClick = () => {
    let data = { id: currentId, type: "leave",  userId:currentUser.id };
    dispatch(changeFcEventsUserStatus(data)).then((res) => {
      setReady(false);
    });
  };
  const buttonByRole2 = useMemo(() => {
      return {
        icon: "qr",
        text: "Показати QR",
        onButtonClick: () => {
          setModalQrOpen(true)
        },
      };
  }, [contentId, currentEvent]);

  const buttonByRole3 = useMemo(() => {
    return {
      icon: "copy",
      text: "Поділитися",
      onButtonClick: () => {
        copyClick();
        setAlert(true);
        setAlertMessage("Скопійовано");
      },
    };
}, [contentId, currentEvent]);
  const buttonByRole = useMemo(() => {
    // if (contentId === "events"&& fc && fc.clubUser && (fc.clubUser.isCoordinator||fc.clubUser.isActing)) {
    if (
      (isAdmin ||
      currentEvent.eventUser?.isCoordinator ||
      currentEvent.eventUser?.isLeading)&& currentEvent.holdingStatusId!== "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp"
    ) {
      return {
        icon: "edit",
        text: "Редагувати",
        onButtonClick: () => {
          navigate("/eventsItem/" + clubid + "/" + currentId);
        },
      };
    }
    if (!auth.isLoggedIn) {
      return {
        icon: "login",
        text: "Авторизуватися",
        onButtonClick: () => {
          navigate("/login");
        },
      };
    }
    if (
      currentEvent.eventUser === null &&
      currentEvent.holdingStatusId !== "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp" &&
      currentEvent.isPrivate
    ) {
      return {
        icon: "login",
        text: "Зареєструватись на захід",
        disabled:disabledButton,
        onButtonClick: applyEvent,
      };
    }
    if (
      currentEvent.eventUser === null &&
      currentEvent.holdingStatusId !== "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp" &&
      !currentEvent.isPrivate && currentUser
    ) {
      return {
        icon: "login",
        text: "Приєднатися до заходу",
        onButtonClick: currentEvent.isPersonalInfo? tryJoinEvent :joinEvent,
      };
    }

    if (currentEvent.eventUser?.isApproved && !currentEvent.eventUser.isCoordinator &&  currentEvent.holdingStatusId !== "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp") {
      return {
        icon: "logout",
        text: "Покинути захід",
        onButtonClick: leaveClub,
      };
    }

    if (
      currentEvent.eventUser &&
      !currentEvent.eventUser.isApproved &&
      !currentEvent.eventUser.isCoordinator &&
      !currentEvent.eventUser?.isActing
    ) {
      return {
        icon: "logout",
        text: "Відмінити заявку",
        onButtonClick: cancelClick,
      };
    }
    return;
  }, [contentId, currentEvent]);

  const navbar = [
    {
      icon: "news",
      children: "Про захід",
      route: "about",
    },
    {
      icon: "rocket",
      children:
        currentEvent.typeId === "e58e5c86-5ca7-412f-94f0-88effd1a45a8"
          ? "Запис"
          : currentEvent.typeId === "bd09f36f-835c-49e4-88b8-4f835c1602ac"
          ? "Маршрут"
          : "",
      route: "itinerary",
      hidden:
        currentEvent.typeId === "848e3121-4a2b-413d-8a8f-ebdd4ecf2840" ||
        (currentEvent.typeId === "e58e5c86-5ca7-412f-94f0-88effd1a45a8" &&
          currentEvent.holdingStatusId !==
            "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp")
          ? true
          : false,
    },
    {
      icon: "users",
      children: "Галерея",
      route: "gallery",
      hidden: currentEvent.holdingStatusId === "6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p"
    },
    {
      icon: "users",
      children: "Учасники",
      route: "users",
    },
  ];
  const content = useMemo(() => {
    switch (contentId) {
      case "users":
        return <Users />;
      case "gallery":
          return <Gallery />;

      case "itinerary":
        if (currentEvent.typeId === "e58e5c86-5ca7-412f-94f0-88effd1a45a8") {
          return (
            <Itinerary
              currentEvent={currentEvent}
              link={currentEvent.conferenceLink}
            />
          );
        }
        if (currentEvent.typeId === "bd09f36f-835c-49e4-88b8-4f835c1602ac") {
          return <Table formSend={currentEvent} />;
        }

      case "about":
      default:
        return <About />;
    }
  }, [contentId, currentEvent]);

  useEffect(() => {
    if (!clubid || clubid === null) {
      navigate(`/0/${currentId}`);
    }
  }, [clubid, currentId]);

  const initFunc = () => {
    dispatch(getEventsCurrentItem({ id: currentId }))
      .then((res) => {
        if (res.club && res.club.id && (!clubid || clubid === "0")) {
          navigate(`/fc-events/${res.club.id}/${currentId}/${contentId || 'about'}`);
        } else {
          setReady(true);
          if(res.maxCountUser>0 && res.memberAmount>1 && res.memberAmount >= res.maxCountUser){
            if (res.eventUser?.isApproved && !res.eventUser.isCoordinator &&  res.holdingStatusId !== "0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp") {
                setAlertMessage('');
                setDisabledButton(false);
                setAlert(false);
              }else{
                setAlertMessage(res.errorText);
                setDisabledButton(true);
                setAlert(true);
              }

          }

        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(initFunc,30000);
    // очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    if (!ready) {
      setModalAccept(false);
      initFunc();
    }
  }, [ready, clubid]);
  if (ready) {
    return (
      <div className="apFcEventsPage">
        {modalQrOpen && 
        <>
        <QrComponent link={'https://ap.sportforall.gov.ua/qr/event/'+currentId} setModalOpen={setModalQrOpen}  name={currentEvent.title}></QrComponent>
        </>
        }
        {modalAccept &&
          <ModalAcceptComponent setModalAccept={setModalAccept} setForm={setForm} form={form} joinEvent={joinEvent}></ModalAcceptComponent>
        
        }
         {alert && (
          <InfoWindow
            setModal={setAlert}
            message={alertMessage}
          ></InfoWindow>
        )}

        {clubid && (
          <BreadCrumbs
            fc={{
              title: currentEvent.club?.name ? currentEvent.club.name : "Клуб",
              url: `/fc/${clubid}/events`,
            }}
          />
        )}
        <EventHeader
          currentEvent={currentEvent}
          title={currentEvent.title}
          fcId={currentEvent?.club?.id}
          fcName={currentEvent?.club?.name}
          status={currentEvent.holdingStatusId}
          userStatus={currentEvent.eventUser}
          startsAt={currentEvent.startsAt}
          timeZoneDifference={currentEvent.timeZoneDifference}
          date={
            currentEvent.startsAt
              ? getDate(currentEvent.startsAt,'dd/mmmm/yyyy hh:mm',' ').toLowerCase()+' - '+getDate(currentEvent.finishesAt,'hh:mm',':')
              : "2022-00-00 00:00:00"
          }
          link={currentEvent.conferenceLink}
          img={currentEvent.imageUrl}
          address={currentEvent.address}
          memberAmount={currentEvent.memberAmount}
          maxCountUser={currentEvent.maxCountUser}
          
          type={currentEvent.typeId}
          marker={
            currentEvent.sportsground || 
            (currentEvent.routePoints?.length > 0 && currentEvent.routePoints[0])
          }
          meetingRecordsCount={currentEvent.meetingRecordsCount}
        />
        <Tabbar
          data={navbar}
          contentId={contentId}
          currentId={currentId}
          memberAmount={currentEvent.memberAmount}
          maxCountUser={currentEvent.maxCountUser}
          clubId={clubid ? clubid : ""}
          path={"/fc-events"}
          currentEvent={currentEvent}
        //  rating={currentEvent.eventUser?.isApproved &&currentEvent.holdingStatusId==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp'}
          rating={currentEvent.holdingStatusId==='0q8a6xc0-1nb4-1pr4-h5at-4sw3m0l387yp'&& (currentEvent.eventUser?.isApproved && !currentEvent.eventUser?.isCoordinator && !currentEvent.eventUser?.isLeading)}
          additional={
            buttonByRole ? (
              <button
                className={disabledButton?'primaryButtonIconTextDisabled': 'primaryButtonIconTextDefault'}
                onClick={buttonByRole.onButtonClick}
                disabled={disabledButton}
              >
                <Icon type={buttonByRole.icon} />
                {buttonByRole.text}
              </button>
            ) : null
          }
          additional2={
            buttonByRole2 ? (
              <button
                className={'primaryButtonIconTextDefault'}
                onClick={buttonByRole2.onButtonClick}
              >
                <Icon type={buttonByRole2.icon} />
                {buttonByRole2.text}
              </button>
            ) : null
          }
          additional3={
            buttonByRole3 ? (
              <button
                className={'primaryButtonIconTextDefault'}
                onClick={buttonByRole3.onButtonClick}
              >
                <Icon type={buttonByRole3.icon} />
                {buttonByRole3.text}
              </button>
            ) : null
          }
        />
        {content}
      </div>
    );
  } else {
    return <Loader></Loader>;
  }
};
