import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';


const getOfficial = (data)=>{
     
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
     
    if(data.filter&& Object.keys(data.filter).length ){
         
    for (const [key, value] of Object.entries(data.filter)) {
         
        value.forEach(el => {
             
            filter+=`&filters[${key}]=${el}`
        });
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
     
    return axios.get(constants.URL + `api/v1/gallery/${data.sportEventId}/official?${filter}`,{headers: authHeader()}).then(response=>{
         
        return response.data
    }).catch((e)=>{
         
        console.log(e);
      });;
}

const getUserGallery = (data)=>{
     
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
     
    if(data.filter&& Object.keys(data.filter).length ){
         
    for (const [key, value] of Object.entries(data.filter)) {
         
        value.forEach(el => {
             
            filter+=`&filters[${key}]=${el}`
        });
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
     
    return axios.get(constants.URL + `api/v1/gallery/${data.sportEventId}/user?${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const changeGalleryStatus = (data)=>{
    return axios.post(constants.URL + `api/v1/gallery-official/${data.type}`,data,{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
}

const approveGalleryStatus = (data)=>{
    return axios.post(constants.URL + `/api/v1/gallery-user/${data.id}/approve`,data,{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
}


const deleteGallery = (data)=>{
    return axios.delete(constants.URL + `api/v1/gallery-${data.type}/` + data.id,{ headers: authHeader()})
    .then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


export default{
    getOfficial,
    getUserGallery,
    changeGalleryStatus,
    deleteGallery,
    approveGalleryStatus
}