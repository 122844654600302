import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';


const getSupport = (data)=>{
     
    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
     
    if(data.filter&& Object.keys(data.filter).length ){
         
    for (const [key, value] of Object.entries(data.filter)) {
         
        value.forEach(el => {
             
            filter+=`&filters[${key}]=${el}`
        });
    }
    } 
    if(data.sort&& Object.keys(data.sort).length ){
        for (const [key, value] of Object.entries(data.sort)) {
                filter+=`&sort[${key}]=${value}`
        }
    }    
     
    return axios.get(constants.URL + `api/v1/support-tickets?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
         
        return response.data
    }).catch((e)=>{
         
        console.log(e);
      });;
}

const addSupport = (data)=>{
    
    if(data){
        return axios.post(constants.URL + `api/v1/support-tickets/${data.id}/${data.type}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }else{
        return axios.post(constants.URL + `api/v1/support-tickets`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }
    
}

const sendSupport = (data)=>{
    return axios.post(constants.URL + 'api/v1/support-tickets/'+data.id+'/messages',{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}



const editSupport = (data)=>{
    return axios.put(constants.URL + 'api/v1/support-tickets/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getSupportItem = (data)=>{
    return axios.get(constants.URL +'api/v1/support-tickets/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteSupport = (data)=>{
    return axios.delete(constants.URL + 'api/v1/support-tickets/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}


export default{
    getSupport,
    addSupport,
    editSupport,
    getSupportItem,
    deleteSupport,
    sendSupport
}