import axios from "axios";
import authHeader from "./auth-header";

import constants from '../../constants/constant.js';


const getVideos = (data)=>{

    if(!data.offset){
        data.offset=0;
    } if (!data.limit){
        data.limit=10
    }
    let filter=''
if(data.filter&& Object.keys(data.filter).length ){
    for (const [key, value] of Object.entries(data.filter)) {
        value.forEach(el => {
            
            filter+=`&filters[${key}]=${el}`
        });
    }
}
if(data.sort&& Object.keys(data.sort).length ){
    for (const [key, value] of Object.entries(data.sort)) {
            filter+=`&sort[${key}]=${value}`
    }
}    

 if(data.id){
    return axios.get(constants.URL + `/api/v1/videos/${data.id}/random?offset=${data.offset}&limit=${data.limit}${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;

 }else{
     
    return axios.get(constants.URL + `api/v1/videos?offset=${data.offset}&limit=${data.limit}&sort[priority]=desc${filter}`,{headers: authHeader()}).then(response=>{
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
 }

}


const addVideo = (data)=>{
    
    if(data){
        return axios.post(constants.URL + `api/v1/videos/${data.id}/${data.type}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }else{
        return axios.post(constants.URL + `api/v1/videos`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
              
            console.log(e);
        });
    }
    
}

const voteVideo = (data)=>{
    
        return axios.post(constants.URL + `api/v1/videos/${data.id}/${data.type}`,{},{ headers: authHeader()})
        .then((response) => { 
            return response.data})
        .catch((e)=>{
            console.log(e);
        });
}

const qrReadVideo=(data)=>{
    return axios.post(constants.URL + `api/v1/statistics/region-name`,{
        regionName: data.regionId,
        gromada: data.idgromady,
        userId: data.userId
    },{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });

}


const editVideo = (data)=>{
    return axios.put(constants.URL + 'api/v1/videos/'+data.id,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getVideoItem = (data)=>{
    return axios.get(constants.URL +'api/v1/videos/'+data.id,{headers: authHeader()}).then(response=>{
        
        return response.data
    }).catch((e)=>{
        console.log(e);
      });;
}

const deleteVideo = (data)=>{
    return axios.delete(constants.URL + 'api/v1/uploads/' + data.id,{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

export default{
    getVideos,
    addVideo,
    editVideo,
    getVideoItem,
    deleteVideo,
    voteVideo,
    qrReadVideo
}