import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.js';

const uploadFile = (data, url) => {
    // return axios.post(constants.URL + `api/v1/assets`,data,{ headers: {'Content-Type': 'multipart/form-data'} ,body: JSON.stringify(data)});

    const curreltLink = url || `api/v1/uploads`;

    let auth = authHeader();
    return axios({
        method: "post",
        url: constants.URL + curreltLink,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': auth.Authorization
        },
    })

}


export default {
    uploadFile,
}