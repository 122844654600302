import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import Tile from "../../../components/Tile";
import { getFcEventsUserApplying, getFcEventsUserHeads, getFcEventsUserMembers, getFcEventsUserParticipants, getEventCountUsers } from "../../../redux/actions/fcEventsUsers";


export const Users = (props) => {
  const [ready,setReady]=useState(false);
  const dispatch=useDispatch();
  const currentId = useParams().id;
  const { fcUsersHead,fcUsersMember,fcUsers,currentEvent,currentUser } = useSelector((state) => state);

  const initialFunc= async()=>{
    await getFcHead();
    await getFcUsers();
    if(currentEvent.holdingStatusId === "6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p"){
      await  getFcMembers();
    }else{
      await getFcParticipants()
    }
    setReady(true);
  }

  const getFcMembers=()=>{
    let data={id:currentId}
    dispatch(getFcEventsUserMembers(data))
  }
  const getFcParticipants=()=>{
    let data={id:currentId}
    dispatch(getFcEventsUserParticipants(data))
  }


  const getFcUsers=()=>{
    let data={id:currentId}
    dispatch(getFcEventsUserApplying(data))
  }

  const getFcHead=()=>{
    let data={id:currentId}
    dispatch(getFcEventsUserHeads(data))
  }


  const downloadClick=()=>{
    let data={eventId:currentId}
    dispatch(getEventCountUsers(data)).then(res=>{
      window.open(res.dirname)
    })
  }

  useEffect(() => {
    if(!ready){
      initialFunc()
    }
  },[ready]);

  if(!ready || !fcUsersHead || !fcUsers || !fcUsersMember ){
    return(
      <Loader></Loader>
    )

  }else{
  return (
    <div className="apFcUsers">

              <div className="apFcUsersRow">
                <h3 style={{display:'flex', alignItems:'center'}}>Адміністратори заходу {currentEvent.isPersonalInfo &&(currentUser.currentUser?.roleId==='631db81f-fa07-42e2-b394-062c9be66b09' || currentUser.currentUser?.roleId==='5dcf0363-d171-45db-9280-cb337ca5e101' || (currentUser.currentUser?.roleId==='09efbeb2-f45a-418d-89b0-b2a4c37f6122' && fcUsersHead.items?.some(obj => obj.id === currentUser.currentUser?.id))) && <button style={{marginLeft:'20px'}} className="primaryButtonIconTextDefault" onClick={downloadClick}>Завантажити список користувачів</button>} </h3> 
                {fcUsersHead && fcUsersHead.items && fcUsersHead.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USERFC"}
                        img={el.photo}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}

                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        gender={el.sex}
                        key={index}
                      />
                    );
                  }
                )}
                </div>

                {currentEvent && currentEvent.eventUser && (currentEvent.eventUser.isCoordinator ||currentEvent.eventUser?.isActing ) && fcUsers?.items?.length>0 &&<div className="apFcUsersRow">
                <h3>Заявки на участь у заході</h3>
                {fcUsers && fcUsers.items && fcUsers.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USERFCINVITE"}
                        img={el.photo}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}
                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        gender={el.sex}
                        key={index}

                      />
                    );
                  }
                )}
                </div>}
                 <div className="apFcUsersRow">
               {fcUsersMember?.items?.length>0&& <h3>{currentEvent.holdingStatusId === "6h8ad9c0-fd34-8kr4-h8ft-43vdm3n7do3p" ? 'Зареєстровані учасники заходу':'Учасники заходу'}</h3>}
                {fcUsersMember && fcUsersMember.items && fcUsersMember.items.map(
                  (
                    el,
                    index
                  ) => {
                    return (
                      <Tile
                        setReady={setReady}
                        el={el}
                        type={"USERFC"}
                        img={el.photo}
                        nickname={el.nickname}
                        contact={el.email}
                        age={el.age}
                        // status={status}
                        adress={el.city}
                        // rating={rating}
                        id={el.id}
                        sex={el.sex}
                        key={index}
                      />
                    );
                  }
                )}

                
              </div>
    </div>
  );
}
};
